import RoutesNames from '@/router/routesNames';
import PassThroughVue from '@/components/layouts/PassThrough.vue';
import { ACCOUNT_TYPES, ROUTE_ROLES } from '@/constants';
const SYSTEM_ROUTER_NAMES = {
  login: RoutesNames.system[0],
  home: RoutesNames.system[1],
  account: "system_account",
  passwordChange: "system_passwordChange",
  schoolAccounts: "system_schoolAccounts",
  schoolDetail: "system_schoolDetail",
  createSchool: "system_createSchool",
  billingList: "system_billingList",
  invoicing: "system_invoicing",
  students: "system_students",
  studentDetail: "system_studentDetail",
  stores: "system_stores",
  storeDetail: "system_storeDetail",
  createStore: "system_createStore",
  storeCategories: "system_storeCategories",
  pointUsageHistories: "system_pointUsageHistories",
  salesPayment: "system_salesPayment",
  areas: "system_areas",
  setting: "system_setting",
  mailManagement: "system_mailManagement",
}

const systemRouters = {
  path: "/system",
  name: "system_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: SYSTEM_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "511" */ "@/views/system/auth/View511.vue"),
    },
    {
      path: "dashboard",
      name: SYSTEM_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "531" */ "@/views/system/pages/View531.vue"),
    },
    {
      path: "account",
      name: SYSTEM_ROUTER_NAMES.account,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "551" */ "@/views/system/pages/View551.vue"),
    },
    {
      path: "password-change",
      name: SYSTEM_ROUTER_NAMES.passwordChange,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "552" */ "@/views/system/pages/View552.vue"),
    },
    {
      path: "school-accounts",
      name: SYSTEM_ROUTER_NAMES.schoolAccounts,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "571" */ "@/views/system/pages/View571.vue"),
    },
    {
      path: "school/:schoolId",
      name: SYSTEM_ROUTER_NAMES.schoolDetail,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () => import("@/views/system/pages/View572.vue"),
    },
    {
      path: "create-school",
      name: SYSTEM_ROUTER_NAMES.createSchool,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () => import("@/views/system/pages/View573.vue"),
    },
    {
      path: "billing-list",
      name: SYSTEM_ROUTER_NAMES.billingList,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "581" */ "@/views/system/pages/View581.vue"),
    },
    {
      path: "invoicing/:id",
      name: SYSTEM_ROUTER_NAMES.invoicing,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "582" */ "@/views/system/pages/View582.vue"),
    },
    {
      path: "students",
      name: SYSTEM_ROUTER_NAMES.students,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "591" */ "@/views/system/pages/View591.vue"),
    },
    {
      path: "students/:studentId",
      name: SYSTEM_ROUTER_NAMES.studentDetail,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () => import("@/views/system/pages/View592.vue"),
    },
    {
      path: "stores",
      name: SYSTEM_ROUTER_NAMES.stores,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "611" */ "@/views/system/pages/View611.vue"),
    },
    {
      path: "stores/:storeId",
      name: SYSTEM_ROUTER_NAMES.storeDetail,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () => import("@/views/system/pages/View612.vue"),
    },
    {
      path: "create-store",
      name: SYSTEM_ROUTER_NAMES.createStore,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () => import("@/views/system/pages/View614.vue"),
    },
    {
      path: "store-categories",
      name: SYSTEM_ROUTER_NAMES.storeCategories,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "613" */ "@/views/system/pages/View621.vue"),
    },
    {
      path: "point-usage-histories",
      name: SYSTEM_ROUTER_NAMES.pointUsageHistories,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "631" */ "@/views/system/pages/View631.vue"),
    },
    {
      path: "sales-payment",
      name: SYSTEM_ROUTER_NAMES.salesPayment,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "651" */ "@/views/system/pages/View651.vue"),
    },
    {
      path: "areas",
      name: SYSTEM_ROUTER_NAMES.areas,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "702" */ "@/views/system/pages/View702.vue"),
    },
    {
      path: "setting",
      name: SYSTEM_ROUTER_NAMES.setting,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "611" */ "@/views/system/pages/View661.vue"),
    },
    {
      path: "mail-management",
      name: SYSTEM_ROUTER_NAMES.mailManagement,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
        routeRole: ROUTE_ROLES.SYSTEM,
      },
      component: () =>
        import(/* webpackChunkName: "MailManagement" */ "@/views/system/pages/ViewMailManagement.vue"),
    },
  ]
}

export {
  systemRouters,
  SYSTEM_ROUTER_NAMES
}
