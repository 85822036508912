import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrashCan,
  faEye,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCircleUser,
  faUserPlus,
  faUser,
  faGear,
  faUserGroup,
  faCoins,
  faBookOpen,
  faP,
  faEyeSlash,
  faClipboardList,
  faEnvelope,
  faCheck,
  faAngleRight,
  faDeleteLeft,
  faYenSign,
  faShop,
  faBuildingColumns,
  faCircleExclamation,
  faSchool,
  faBars,
  faCamera,
  faPlusCircle,
  faArrowRight,
  faPeopleGroup,
  faXmark,
  faStore,
  faLocationDot,
  faMoneyBill1,
  faLockOpen,
  faUnlock
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircleRight,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faUnlock,
  faLockOpen,
  faMoneyBill1,
  faLocationDot,
  faStore,
  faPeopleGroup,
  faTrashCan,
  faChevronRight,
  faSchool,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faEye,
  faCircleUser,
  faUser,
  faUserPlus,
  faGear,
  faUserGroup,
  faCoins,
  faBookOpen,
  faP,
  faEyeSlash,
  faClipboardList,
  faEnvelope,
  faCheck,
  faAngleRight,
  faDeleteLeft,
  faCircleRight,
  faYenSign,
  faShop,
  faBuildingColumns,
  faCircleCheck,
  faCircleExclamation,
  faBars,
  faCamera,
  faPlusCircle,
  faArrowRight,
  faXmark
);

export default FontAwesomeIcon;
