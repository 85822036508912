<template>
  <div
    :class="{
      'sidebar--system': isSystem,
      open: sidebarOpen,
    }"
    class="sidebar vh-100"
  >
    <div
      class="btn sidebar--open__btn mt-1 me-1 ms-1"
      @click="sidebarOpen = !sidebarOpen"
    >
      <font-awesome-icon :icon="['fas', 'xmark']" style="color: #ffffff" />
    </div>
    <div class="sidebar--container" :class="{ 'scroll-hidden': !scrollNow}">
      <div class="sidebar__title text-center">
        <router-link class="sidebar__link" active-class="sidebar__link--active" to="/">
          <img src="@/assets/preme.png" class="sidebar-logo" alt="logo" />
        </router-link>
      </div>
      <router-link
        :class="isSystem ? 'sidebar__link--system' : ''"
        class="sidebar__link"
        v-for="(item, index) in sidebars"
        :key="index"
        active-class="sidebar__link--active"
        :to="item.link"
      >
        <div class="sidebar__item">
          {{ item.title }}
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="icon__chevron--right"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/layouts/sidebar.scss";
</style>

<script>
import routesNames from "@/router/routesNames";
import { LOGOUT } from "@/store/actions.type";
import emitter from "@/mitt";
import { SYSTEM_ROUTER_NAMES } from "@/router/systemGroup";

export default {
  name: "SidebarSystem",
  created() {
    emitter.on(`toggleSidebar`, () => {
      this.sidebarOpen = !this.sidebarOpen;
    });
  },
  computed: {
    sidebars() {
      return [
        {
          title: this.$t("system.menu_items.university_account_list"),
          link: { name: SYSTEM_ROUTER_NAMES.schoolAccounts },
        },
        {
          title: this.$t("system.menu_items.university_account_issue"),
          link: { name: SYSTEM_ROUTER_NAMES.createSchool },
        },
        {
          title: this.$t("system.menu_items.university_billing_list"),
          link: { name: SYSTEM_ROUTER_NAMES.billingList },
        },
        {
          title: this.$t("system.menu_items.student_account_list"),
          link: { name: SYSTEM_ROUTER_NAMES.students },
        },
        {
          title: this.$t("system.menu_items.restaurant_list"),
          link: { name: SYSTEM_ROUTER_NAMES.stores },
        },
        {
          title: this.$t("system.menu_items.restaurant_account_issue"),
          link: { name: SYSTEM_ROUTER_NAMES.createStore },
        },
        {
          title: this.$t("system.menu_items.restaurant_category_setting"),
          link: { name: SYSTEM_ROUTER_NAMES.storeCategories },
        },
        {
          title: this.$t("school.menu_items.point_use_history"),
          link: { name: SYSTEM_ROUTER_NAMES.pointUsageHistories },
        },
        {
          title: this.$t("system.menu_items.monthly_sales_deposit_list"),
          link: { name: SYSTEM_ROUTER_NAMES.salesPayment },
        },
        {
          title: this.$t("system.menu_items.master_setting"),
          link: { name: SYSTEM_ROUTER_NAMES.account },
        },
        {
          title: this.$t("system.menu_items.management_area"),
          link: { name: SYSTEM_ROUTER_NAMES.areas },
        },
        {
          title: "マスタ設定",
          link: { name: SYSTEM_ROUTER_NAMES.setting },
        },
        {
          title: "メール管理",
          link: { name: SYSTEM_ROUTER_NAMES.mailManagement },
        },
      ];
    },
  },
  beforeUnmount() {
    emitter.off("toggleSidebar");
  },
  data() {
    return {
      sidebarOpen: false,
      scrollNow: false,
      timer: false,
    };
  },
  mounted: function () {
    document
      .querySelector(".sidebar--container")
      .addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollNow = true;
      if (this.timer != false) clearTimeout(this.timer);
      this.timer = setTimeout( () => {
        this.scrollNow = false;
      }, 2000);
    },
    logout() {
      this.$store.dispatch(LOGOUT, routesNames.system[0]);
    },
  },
  props: {
    isSystem: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
