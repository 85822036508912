import {
  SystemCreateAccount,
  SystemCreateSchool,
  SystemCreateStoreCategory,
  SystemDeleteStoreCategory,
  SystemGetBillingId,
  SystemGetDetailBilling,
  SystemGetListBilling,
  SystemGetListBillingStore,
  SystemGetListSchool,
  SystemGetListStore,
  SystemGetMailTemplate,
  SystemGetPointUsed,
  SystemRefundPoint,
  SystemRegisterAccountManager,
  SystemUpdateBillingStatus,
  SystemUpdateMailTemplate,
  SystemUpdateSchoolStatuses,
  SystemUpdateStoreStatuses,
  createArea,
  createOrUpdateSetting,
  deleteArea,
  getListArea,
  getSetting
} from "@/services/api/system.service";
import {
  SYSTEM_CREATE_SCHOOL,
  SYSTEM_GET_LIST_SCHOOL,
  SYSTEM_REGISTER_ACCOUNT_MANAGER,
  SYSTEM_CREATE_ACCOUNT,
  SYSTEM_CREATE_STORE_CATEGORY,
  SYSTEM_DELETE_STORE_CATEGORY,
  SYSTEM_GET_LIST_POINT_USED,
  SYSTEM_REFUND_POINT,
  SYSTEM_GET_LIST_STORE,
  SYSTEM_UPDATE_SCHOOL_STATUSES,
  SYSTEM_UPDATE_STORE_STATUSES,
  SYSTEM_GET_LIST_BILLING,
  SYSTEM_GET_DETAIL_BILLING,
  SYSTEM_GET_BILLING_ID,
  SYSTEM_GET_AREA,
  SYSTEM_CREATE_AREA,
  SYSTEM_DELETE_AREA,
  SYSTEM_GET_BILLING_STORE,
  SYSTEM_UPDATE_BILLING_STATUS,
  SYSTEM_GET_SETTING,
  SYSTEM_UPDATE_SETTING,
  SYSTEM_GET_MAIL_TEMPLATE,
  SYSTEM_UPDATE_MAIL_TEMPLATE
} from "../actions.type";
import {
  SET_ERROR,
  SET_LIST_SCHOOL,
  SET_LOADING,
  SET_REGISTER_ACCOUNT_MANAGER,
  SET_UPDATED_DATA,
  SET_POINT_USED,
  SET_LIST_STORE,
  SET_LIST_BILLING,
  SET_BILLING_DETAIL,
  SET_BILLING_ID,
  SET_LIST_AREA,
  SET_LIST_BILLING_STORE,
  SET_SETTING,
  SET_MAIL_TEMPLATE
} from "../mutations.type";
import router from "@/router";
import { SYSTEM_ROUTER_NAMES } from "@/router/systemGroup";

const state = {
  errors: null,
  loading: false,
  updatedData: null,
  schools: {},
  pointHistories: {},
  stores: {},
  billings: {},
  billingsStore: {},
  billing: {},
  billingId: {},
  areas: [],
  setting: {},
  mailTemplate: []
};

const getters = {};

const actions = {
  async [SYSTEM_GET_LIST_SCHOOL](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SystemGetListSchool(query);
      context.commit(SET_LIST_SCHOOL, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_LIST_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    query: object
  ) {
    try {
      const response = await SystemGetListStore(query);
      context.commit(SET_LIST_STORE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_AREA](
    context: { commit: (arg0: string, arg1: any) => void }
  ) {
    try {
      const response = await getListArea();
      context.commit(SET_LIST_AREA, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_CREATE_AREA](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      await createArea(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_DELETE_AREA](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number,
  ) {
    try {
      await deleteArea(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_LIST_POINT_USED](
    context: { commit: (arg0: string, arg1: any) => void },
    query: object
  ) {
    try {
      const { data } = await SystemGetPointUsed(query);
      context.commit(SET_POINT_USED, data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SYSTEM_REFUND_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: object
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SystemRefundPoint(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [SYSTEM_REGISTER_ACCOUNT_MANAGER](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: any
  ) {
    try {
      const { data } = await SystemRegisterAccountManager(credentials);
      context.commit(SET_REGISTER_ACCOUNT_MANAGER, data);
      router.push({name: SYSTEM_ROUTER_NAMES.schoolAccounts});
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SYSTEM_CREATE_SCHOOL](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await SystemCreateSchool(body);
      context.commit(SET_UPDATED_DATA, data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push({name: SYSTEM_ROUTER_NAMES.home});
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [SYSTEM_CREATE_ACCOUNT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SystemCreateAccount(body);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push({name: SYSTEM_ROUTER_NAMES.home});
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_CREATE_STORE_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      await SystemCreateStoreCategory(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_DELETE_STORE_CATEGORY](
    context: { commit: (arg0: string, arg1: any) => void },
    storeCategoryId: number,
  ) {
    try {
      await SystemDeleteStoreCategory(storeCategoryId);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_UPDATE_SCHOOL_STATUSES](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SystemUpdateSchoolStatuses(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      router.push({name: SYSTEM_ROUTER_NAMES.schoolAccounts});
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_UPDATE_STORE_STATUSES](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SystemUpdateStoreStatuses(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      router.push({name: SYSTEM_ROUTER_NAMES.stores});
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_UPDATE_BILLING_STATUS](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SystemUpdateBillingStatus(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_LIST_BILLING](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SystemGetListBilling(query);
      context.commit(SET_LIST_BILLING, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_BILLING_STORE](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SystemGetListBillingStore(query);
      context.commit(SET_LIST_BILLING_STORE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_DETAIL_BILLING](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const { data } = await SystemGetDetailBilling(query);
      context.commit(SET_BILLING_DETAIL, data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_BILLING_ID](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const { data } = await SystemGetBillingId(id);
      context.commit(SET_BILLING_ID, data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_SETTING](
    context: { commit: (arg0: string, arg1: any) => void }
  ) {
    try {
      const { data } = await getSetting();
      context.commit(SET_SETTING, data);
      context.commit(SET_ERROR, null);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_UPDATE_SETTING](
    context: { commit: (arg0: string, arg1: any) => void },
    payload: any
  ) {
    try {
      const { data } = await createOrUpdateSetting(payload);
      context.commit(SET_SETTING, data);
      context.commit(SET_ERROR, null);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_GET_MAIL_TEMPLATE](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const { data } = await SystemGetMailTemplate(id);
      context.commit(SET_MAIL_TEMPLATE, data);
      context.commit(SET_ERROR, null);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SYSTEM_UPDATE_MAIL_TEMPLATE](
    context: { commit: (arg0: string, arg1: any) => void },
    payload: any
  ) {
    try {
      const { data } = await SystemUpdateMailTemplate(payload);
      context.commit(SET_ERROR, null);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_LIST_SCHOOL](state: any, responseData: any) {
    state.schools = responseData;
  },
  [SET_UPDATED_DATA](state: any, responseData: any) {
    state.updatedData = responseData;
  },
  [SET_POINT_USED](state: any, responseData: any) {
    state.pointHistories = responseData;
  },
  [SET_LIST_STORE](state: any, responseData: any) {
    state.stores = responseData;
  },
  [SET_LIST_AREA](state: any, responseData: any) {
    state.areas = responseData;
  },
  [SET_LIST_BILLING](state: any, responseData: any) {
    state.billings = responseData;
  },
  [SET_LIST_BILLING_STORE](state: any, responseData: any) {
    state.billingsStore = responseData;
  },
  [SET_BILLING_DETAIL](state: any, responseData: any) {
    state.billing = responseData;
  },
  [SET_BILLING_ID](state: any, responseData: any) {
    state.billingId = responseData;
  },
  [SET_SETTING](state: any, data: any) {
    state.setting = data;
  },
  [SET_MAIL_TEMPLATE](state: any, responseData: any) {
    state.mailTemplate = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
