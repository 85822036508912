import { toParams } from "@/utils/ObjectUtils";
import ApiInstance from "./axiosBase";

import { PATH_URL } from "@/constants";

export const SystemGetListSchool = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.SYSTEM}/school/list` + params);
};

export const SystemGetListStore = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.SYSTEM}/store/list` + params);
};

export const SystemGetPointUsed = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/get-history-point-used` + params);
};

export const SystemRegisterAccountManager = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/register-manager`, body);
};

export const SystemRefundPoint = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/refund-point`, body);
};

export const SystemCreateSchool = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/school/create`, body);
};

export const SystemCreateAccount = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/user/create`, body);
};

export const SystemGetSchools = () => {
  return ApiInstance.get(`${PATH_URL.SYSTEM}/school/list`);
};

export const SystemCreateStoreCategory = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/store-category/create`, body);
};

export const SystemDeleteStoreCategory = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.SYSTEM}/store-category/delete/${id}`);
};

export const SystemUpdateSchoolStatuses = (data: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/school/status/update`, data);
};

export const SystemUpdateStoreStatuses = (data: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/store/status/update`, data);
};

export const SystemGetListBilling = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.SYSTEM}/billing/list` + params);
};

export const SystemGetListBillingStore = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.SYSTEM}/billing/list/store` + params);
};

export const SystemUpdateBillingStatus = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/billing/update/status`, body);
};

export const SystemGetDetailBilling = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.SYSTEM}/billing/detail` + params);
};

export const SystemGetBillingId = (id: number) => {
  return ApiInstance.get(`${PATH_URL.SYSTEM}/billing/${id}`);
};

export const getListArea = () => {
  return ApiInstance.get(`${PATH_URL.SYSTEM}/area/list`);
};
export const createArea = (body: { name: string, id?: number }) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/area/create`, body);
};

export const deleteArea = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.SYSTEM}/area/delete/${id}`);
};

export const getSetting = () => {
  return ApiInstance.get(`${PATH_URL.SYSTEM}/setting/detail`);
};

export const createOrUpdateSetting = (setting: any) => {
  const { id, ...newSetting} = setting;
  let url = `${PATH_URL.SYSTEM}/setting/create`;
  if (id) {
    url = `${PATH_URL.SYSTEM}/setting/${id}/update`;
    return ApiInstance.put(url, newSetting);
  } else {
    return ApiInstance.post(url, newSetting);
  }
};

export const SystemGetMailTemplate = (id?: number) => {
  return ApiInstance.get(`${PATH_URL.SYSTEM}/mail-template/${id}`);
}

export const SystemUpdateMailTemplate = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SYSTEM}/mail-template/update`, body);
};
