export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const RESET_STATE = "resetModuleState";
export const SET_UPDATED_DATA = "setUpdatedData";

// General
export const SET_STUDENT_STATUES = "setStudentStatues";
export const SET_LIST_STORE_CATEGORY = "setListStoreCategory";
export const SET_DETAIL_BANK = "setDetailBank";
export const SET_BANKS = "setBanks";
export const SET_BANK_BRANCHES = "setBankBranches";

// Students
export const SET_REGISTER_STUDENT = "setRegisterStudent";
export const SET_LIST_FACULTIES = "setListFaculties";
export const SET_POINT_STUDENT = "setPointStudent";
export const SET_LIST_STUDENT = "setListStudent";
export const SET_POINT_USED = "setPointUsed";
export const SET_USED_POINT_STUDENT = "setUsedPointStudent";
export const SET_SEND_FAQ_BY_STUDENT = "setSendFaqByStudent";

// Schools
export const SET_SCHOOL_DETAIL = "setSchoolDetail";
export const SET_STUDENT_DETAIL = "setStudentDetail";
export const SET_HISTORY_POINT_STUDENT = "setHistoryPointStudent";
export const SET_HISTORY_POINT_USED = "setHistoryUsingPointUsed";
export const SET_HISTORY_POINT_STATUSES = "setHistoryPointStatuses";
export const SET_DETAIL_HISTORY_POINT = "setDetailHistoryPoint";
export const SET_LIST_AUTOMATIC_POINT = "setListAutomaticPoint";
export const SET_DETAIL_AUTOMATIC_POINT = "setDetailAutomaticPoint";
export const SET_LIST_VALIDATE_ERRORS = "setListValidateErrors";

// System
export const SET_LIST_SCHOOL = "setListSchool";
export const SET_REGISTER_ACCOUNT_MANAGER = "setRegisterAccountManager";
export const SET_LIST_BILLING = "setListBilling";
export const SET_LIST_BILLING_STORE = "setListBillingStore";
export const SET_BILLING_DETAIL = "setBillingDetail";
export const SET_BILLING_ID = "setBillingId";
export const SET_SETTING = "setSetting";
export const SET_MAIL_TEMPLATE = "setMailTemplate";

//Store
export const SET_STORE_DETAIL = "setStoreDetail";
export const SET_LIST_STORE = "setListStore";
export const SET_LIST_AREA = "setListArea";
export const SET_LIST_STAFF = "setListStaff";
export const SET_HISTORY_POINT_USED_STORE = "setHistoryUsingPointUsedStore";

// Setting
export const SET_SETTING_DETAIL = "setSettingDetail";
export const SET_LIST_SETTING = "setListSetting";
